<template>
  <div v-if="stack">
    <div class="stack">
      <div class="stack__content">
        <CyAlert
          theme="error"
          :content="errors"/>

        <vue-showdown
          v-if="!_.isEmpty(readme)"
          class="markdown-body"
          flavor="github"
          :extensions="['showdownHighlight']"
          :options="{ emoji: true, ghCompatibleHeaderId: true, ghCodeBlocks: true, strikethrough: true, ghMentions: true, tables: true, tasklists: true }"
          :markdown="_.unescape(readme.replace(/&#34;/g, '&quot;'))"
          data-cy="stack-readme"/>

        <div
          v-else
          class="empty-state">
          <span class="empty-state__content py-8">
            <v-icon
              class="empty-state__icon"
              size="32">menu_book</v-icon>
            <h2 class="h5 mb-2">{{ $t('addReadme.title') }}</h2>
            <p>{{ $t('addReadme.text') }}</p>
            <a
              :href="$docLinks.stacks.readme"
              class="cy-link cy-link--external"
              rel="noopener noreferrer"
              target="_blank">
              {{ $t('addReadme.link') }}
            </a>
          </span>
        </div>
      </div>

      <CyWizardServiceDetailsSidebar
        v-if="catalogRepository"
        data-cy="stack-side-bar"
        :stack="stack"
        :catalog-repository="catalogRepository"
        class="stack__sidebar"/>
    </div>

    <portal to="header-actions">
      <CyButton
        v-clipboard:copy="pageUrl"
        v-clipboard:success="() => SHOW_ALERT({ type: 'info', content: $t('linkCopied') })"
        data-cy="copy-link-button"
        icon="link"
        variant="secondary"
        theme="primary"
        class="mr-2">
        {{ $t('copyLink') }}
      </CyButton>

      <CyWizardStackCloneMenu
        v-if="catalogRepository"
        :catalog-repository="catalogRepository"/>

      <CyButton
        v-has-rights-to="'CreateProject'"
        variant="primary"
        theme="secondary"
        icon="done"
        @click="$router.push({ name: 'projectFromStack', query: { selectedStackRef: stackRef } })">
        {{ $t('forms.btnUseThisStack') }}
      </CyButton>
      <span class="ml-2"/>
      <CyMenu
        v-if="!_.$isEmpty($static.actionsMenuItems)"
        :items="$static.actionsMenuItems"
        content-class="actions-menu"
        offset-y/>
    </portal>

    <CyModal
      v-if="modals.delete"
      :header-title="$t('confirmRemoveHeader')"
      :action-btn-func="deleteStack"
      :cancel-btn-func="() => { modals.delete = false }"
      :action-btn-text="$t('forms.btnDelete')"
      modal-type="delete"
      small>
      <div class="pb-5">
        <p>{{ $t('confirmRemoveSentence') }}</p>
        <h3>{{ stack.name }}</h3>
      </div>
    </CyModal>

    <CyModal
      v-if="modals.updateDetails"
      :header-title="$t('updateDetailsHeader')"
      :action-btn-disabled="!canSubmitStackInfo"
      :action-btn-func="updateStackInfo"
      :cancel-btn-func="cancelUpdate"
      :action-btn-text="$t('forms.btnSave')"
      :loading="updating"
      modal-type="update"
      data-cy="update-details-modal"
      small>
      <v-text-field
        v-model="stackInfo.name"
        class="mt-5"
        :label="$t('forms.fieldName')"
        persistent-hint
        type="text"/>
      <v-textarea
        v-model="stackInfo.description"
        rows="6"
        class="mt-5"
        :maxlength="stackDescriptionLimit"
        :label="$t('forms.fieldDescription')"/>
    </CyModal>

    <CyModal
      v-if="modals.updateImage"
      class="image-update__content"
      :header-title="$t('updateImageHeader', { stackName: stack.name })"
      :action-btn-disabled="!canSubmitStackInfo"
      :action-btn-func="updateStackInfo"
      :cancel-btn-func="cancelUpdate"
      :action-btn-text="$t('forms.btnSave')"
      :loading="updating"
      modal-type="update"
      data-cy="update-image-modal"
      small>
      <div class="d-flex">
        <v-col
          cols="10"
          class="d-flex align-enter justify-center">
          <v-text-field
            v-model="updateImage"
            class="header__image-url"
            :label="$t('coverImage')"
            :hint="$t('imageHint')"
            persistent-hint
            :error-messages="imageErrors"
            type="text"/>
        </v-col>
        <v-col cols="2">
          <CyStackAvatar
            :loading="updating"
            :stack="stackInfo"
            :size="58"
            @success="hasImageErrors = false"
            @error="stackInfo.image ? hasImageErrors = true : null"/>
        </v-col>
      </div>
    </CyModal>

    <CyModal
      v-if="modals.changeVisibility"
      :header-title="$t('stacks.visibility.changeVisibility')"
      :loading="updating"
      :action-btn-func="updateStackInfo"
      action-btn-icon=""
      :cancel-btn-text="$t('forms.btnCancel')"
      :cancel-btn-func="$toggle.modals.changeVisibility"
      :action-btn-text="$t('stacks.visibility.set')"
      :close-modal-on-action-click="false"
      :width="555"
      modal-type="info">
      <CyWizardStackVisibilityList :visibility.sync="stackInfo.visibility"/>
    </CyModal>

    <CyModal
      v-if="modals.assignMaintainer"
      :header-title="$t('stacks.maintainer.assignMaintainer')"
      :loading="updating"
      action-btn-icon=""
      :action-btn-func="updateStackInfo"
      :cancel-btn-text="$t('forms.btnCancel')"
      :cancel-btn-func="$toggle.modals.assignMaintainer"
      :action-btn-text="$t('stacks.maintainer.assignMaintainer')"
      :close-modal-on-action-click="false"
      :width="555"
      modal-type="info">
      <p>{{ $t('stacks.maintainer.description') }}</p>
      <CyWizardStackMaintainerSelector
        :teams="teams"
        :selected.sync="maintainer"/>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { CycloidIcons } from '@/assets/icons'
import CyStackAvatar from '@/components/stack-avatar.vue'
import CyWizardServiceDetailsSidebar from '@/components/wizard/service-details-sidebar.vue'
import CyWizardStackCloneMenu from '@/components/wizard/stack-clone-menu.vue'
import CyWizardStackMaintainerSelector from '@/components/wizard/stack-maintainer-selector.vue'
import CyWizardStackVisibilityList from '@/components/wizard/stack-visibility-list.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { required, url } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageStack',
  components: {
    CyStackAvatar,
    CyWizardServiceDetailsSidebar,
    CyWizardStackCloneMenu,
    CyWizardStackVisibilityList,
    CyWizardStackMaintainerSelector,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.stack?.name, [
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  props: {
    stackRef: {
      type: String,
      required: true,
    },
  },
  validations: {
    stackInfo: {
      description: {},
      image: {
        url,
        imageFound (url) {
          return _.isEmpty(url) || !this.hasImageErrors
        },
      },
      name: { required },
    },
  },
  data: () => ({
    hasImageErrors: false,
    updating: false,
    deleting: false,
    pageUrl: window.location.href,
    modals: {
      delete: false,
      updateDetails: false,
      updateImage: false,
      changeVisibility: false,
      assignMaintainer: false,
    },
    newMaintainer: null,
    stackInfo: {
      description: '',
      image: null,
      name: '',
      visibility: '',
    },
  }),
  computed: {
    ...mapState('organization', {
      teams: (state) => state.available.teams,
    }),
    ...mapState('organization/stack', {
      errors: (state) => state.errors.stack,
    }),
    ...mapGetters('organization/stack', [
      'stackDescriptionLimit',
    ]),
    ...mapGetters('organization/stack', [
      'stack',
      'stackBelongsToCurrentOrg',
    ]),
    ...mapGetters('organization/stack/catalogRepository', [
      'catalogRepository',
    ]),
    $static () {
      return {
        actionsMenuItems: [
          {
            icon: 'edit',
            label: this.$t('editDetails'),
            action: () => { this.modals.updateDetails = true },
            condition: this.canUpdateStack,
          },
          {
            icon: 'image',
            label: this.$t('editImage'),
            action: () => { this.modals.updateImage = true },
            condition: this.canUpdateStack,
          },
          {
            icon: 'delete',
            label: `${this.$t('forms.btnDelete')} ${_.toLower(this.$t('untranslated.stack'))}`,
            action: () => { this.modals.delete = true },
            condition: this.canDeleteStack,
          },
          {
            divider: true,
            condition: this.canUpdateVisibilityOrMaintainer,
          },
          {
            icon: 'visibility',
            label: `${this.$t('stacks.visibility.changeVisibility')}...`,
            action: () => { this.modals.changeVisibility = true },
            condition: this.canUpdateVisibilityOrMaintainer,
          },
          {
            icon: 'group',
            label: `${this.$t('stacks.maintainer.assignMaintainer')}...`,
            action: () => { this.modals.assignMaintainer = true },
            condition: this.canUpdateVisibilityOrMaintainer,
          },
        ].filter(({ condition }) => condition),
      }
    },
    updateImage: {
      get () {
        return this.stackInfo.image
      },
      set (newVal) {
        if (!newVal) {
          this.$delete(this.stackInfo, 'image')
          this.hasImageErrors = false
          return
        }

        this.$set(this.stackInfo, 'image', newVal)
        this.$v.stackInfo.image.$touch()
      },
    },
    maintainer: {
      get () {
        return this.stackInfo?.team?.canonical
      },
      set (value) {
        this.newMaintainer = _.find(this.teams, { canonical: value })?.canonical
      },
    },
    canGetTeams () {
      return this.$cycloid.permissions.canDisplay('GetTeams')
    },
    canUpdateStack () {
      const { stack, stackRef } = this
      const isInfraImport = _.has(stack, 'import_status')
      const hasPermission = this.$cycloid.permissions.canDisplay('UpdateServiceCatalog', stackRef)
      return hasPermission && isInfraImport
    },
    canUpdateVisibilityOrMaintainer () {
      return this.$cycloid.permissions.canDisplay('UpdateServiceCatalog', this.stackRef) &&
        this.stackBelongsToCurrentOrg
    },
    canDeleteStack () {
      const { stack, stackRef } = this
      const isInfraImport = _.has(stack, 'import_status')
      const hasPermission = this.$cycloid.permissions.canDisplay('DeleteServiceCatalog', stackRef)
      return hasPermission && isInfraImport
    },
    canSubmitStackInfo () {
      return _.every([
        this.$hasDataChanged('stackInfo'),
        !this.$v.stackInfo.$invalid,
        !this.hasImageErrors,
      ])
    },
    imageErrors () {
      const errors = []
      const { $dirty, url, imageFound } = this.$v.stackInfo.image
      if (!$dirty) return errors
      if (!url) errors.push(this.$t('forms.fieldInvalidUrl'))
      if (!imageFound) errors.push(this.$t('forms.fieldImageNotFound'))
      return errors
    },
    isPublic () {
      return this.stack.status === 'public'
    },
    repoImageLink () {
      if (!this.catalogRepository?._repoURL) return ''

      return this.catalogRepository?._repoURL.getLink()
        .replace('https://github.com/', 'https://raw.githubusercontent.com/')
        .replace('/tree', '')
    },
    readme () {
      const imageLink = this.repoImageLink || this.getStackImageLink()
      const readme = this.stack.readme

      return readme && this.isPublic
        ? readme.replaceAll(/src="(?!http)+([\w:/.-])+/g, (match) => `src="${imageLink}/${match.replace('src="', '')}`)
        : readme && readme.replaceAll(/src=|(https:\/\/\S+(\.png|\.jpg|\.jpeg|\.gif)|http:\/\/\S+(\.png|\.jpg|\.jpeg|\.gif))/g,
          (match) => this.replacePrivateImages(match))
    },
  },
  async mounted () {
    const { stackRef } = this
    await Promise.all([this.getTeams(), this.GET_STACK({ stackRef })])
    this.setStackInfo()
  },
  beforeDestroy () {
    this.RESET_STACK_STATE()
    this.RESET_CR_STATE()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK',
      'DELETE_STACK',
      'UPDATE_STACK',
    ]),
    ...mapMutations('organization/stack', [
      'RESET_STACK_STATE',
    ]),
    ...mapMutations('organization/stack/catalogRepository', [
      'RESET_CR_STATE',
    ]),
    replacePrivateImages (match) {
      return match.includes('src=')
        ? match.replaceAll(match, `width="50" src="${CycloidIcons.inaccessible}"`)
        : match.replaceAll(match, `${CycloidIcons.inaccessible}`)
    },
    async getTeams () {
      if (this.canGetTeams) {
        await this.FETCH_AVAILABLE({ keyPath: 'teams' })
      }
    },
    getStackImageLink () {
      if (!this.stack.image) return ''

      const regex = /^(?<https>https?):\/\/(?<domain>[\w.-]+)\/(?<org>[\w.-]+)\/(?<repo>[\w.-]+)\/(?<branch>[\w.-]+)\//g
      const { https, domain, org, repo, branch } = regex.exec(this.stack.image)?.groups || {}

      return `${https}://${domain}/${org}/${repo}/${branch}`
    },
    async deleteStack () {
      const { stack, $router } = this
      this.deleting = true
      await this.DELETE_STACK({ stack, $router })
      this.deleting = false
      if (!_.isEmpty(this.errors)) this.modals.delete = false
    },
    cancelUpdate () {
      this.$resetData('stackInfo')
      this.closeModals()
    },
    closeModals () {
      for (const key in this.modals) this.modals[key] = false
    },
    setStackInfo () {
      this.stackInfo = _.cloneDeep(this.stack)
      this.$setOriginalData('stackInfo')
    },
    async updateStackInfo () {
      const { newMaintainer, stackInfo, stackRef } = this
      this.updating = true
      await this.UPDATE_STACK({
        stack: {
          ...stackInfo,
          team_canonical: newMaintainer,
        },
      }) && this.GET_STACK({ stackRef })
      this.updating = false
      this.closeModals()
      this.setStackInfo()
    },
  },
  i18n: {
    messages: {
      en: {
        confirmRemoveHeader: 'Remove stack',
        confirmRemoveSentence: 'Are you sure that you want to remove this stack?',
        copyLink: 'Copy link',
        coverImage: 'Cover image (url)',
        imageHint: 'Enter an image URL, to be used as cover image for your @:Stack. Recommended size: 100 x 100px',
        editDetails: 'Edit details',
        editImage: 'Edit image',
        linkCopied: 'A link to this page has been copied to your clipboard.',
        title: '@:routes.stack',
        updateDetailsHeader: 'Edit @:Stack details',
        updateImageHeader: 'Change @:Stack cover image',
        addReadme: {
          title: 'Add a README',
          text: `We couldn't find a README.md file in the source code, add one to showcase the details of this stack.`,
          link: 'Learn more about READMEs',
        },
      },
      es: {
        confirmRemoveHeader: 'Eliminar stack',
        confirmRemoveSentence: '¿Estás seguro de querer eliminar este stack?',
        copyLink: 'Copiar enlace',
        coverImage: 'Imagen de portada (url)',
        imageHint: 'Ingrese la URL de una imagen, para ser utilizada como portada de su @:Stack. Tamaño recomendado: 100 x 100px',
        editDetails: 'Editar detalles',
        editImage: 'Editar imagen',
        linkCopied: 'Un enlace a esta página ha sido copiado a su portapapeles.',
        title: '@:routes.stack',
        updateDetailsHeader: 'Editar los detalles del @:Stack',
        updateImageHeader: 'Cambiar imagen del @:Stack',
        addReadme: {
          title: 'Agregar un README',
          text: `No pudimos encontrar un archivo README.md en el código fuente, agréguelo para mostrar los detalles de esta configuración.`,
          link: 'Obtenga más información sobre los archivos README',
        },
      },
      fr: {
        confirmRemoveHeader: 'Retirer ce stack',
        confirmRemoveSentence: 'Êtes-vous sûr de vouloir retirer ce stack?',
        copyLink: 'Copier le lien',
        coverImage: 'Image de couverture (url)',
        imageHint: `Entrez une URL d'image, à utiliser comme image de couverture pour votre @:Stack. Taille recommandée: 100 x 100px`,
        editDetails: 'Éditer les détails',
        editImage: `Éditer l'image`,
        linkCopied: 'Un lien vers cette page a été copié dans votre presse-papiers.',
        title: '@:routes.stack',
        updateDetailsHeader: 'Modifier les détails du @:Stack',
        updateImageHeader: `Changer l'image du @:Stack`,
        addReadme: {
          title: 'Ajouter un README',
          text: `Nous n'avons pas pu trouver de fichier README.md dans le code source, veuillez en ajouter un pour présenter les détails de cette configuration.`,
          link: 'En savoir plus sur les README',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  margin: 0 -#{$spacer-8} -#{$spacer-8} !important;
}

.stack {
  display: grid;
  grid-template-areas: "content sidebar";
  grid-template-columns: minmax(0, 1fr) auto;
  width: 100%;

  &__content {
    display: flex;
    grid-area: content;
    flex-direction: column;
    padding: #{$spacer-6} #{$spacer-8} 64px;
  }

  &__sidebar {
    grid-area: sidebar;
    padding: #{$spacer-6} #{$spacer-8};
    border-left: solid 1px cy-get-color("grey", "light-2");
  }
}

.markdown-body {
  width: 100%;
  max-width: 1012px;
  margin: 0 auto;
  background-color: transparent;

  ::v-deep {
    a {
      color: cy-get-color("secondary");
    }

    img {
      max-width: 100%;
    }

    pre {
      background-color: cy-get-color("grey", "light-3");

      code {
        background-color: transparent;
      }
    }
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 512px;
  margin: auto;
  text-align: center;

  &__icon {
    margin-bottom: $spacer-6;
    color: cy-get-color("primary", "light-3");
  }
}

.clone-helper-menu {
  .command-snippet {
    border-radius: 4px;
    background-color: cy-get-color("black");
    color: cy-get-color("white");
    font-family: $font-family-code;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.actions-menu .v-icon {
  color: cy-get-color("primary");
}
</style>
